import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import BehinegarLogo from "../resources/images/behinegar.webp";
import Location from "../resources/images/location-dot-thin.svg";
import Phone from "../resources/images/phone-thin.svg";
import Mobile from "../resources/images/mobile-thin.svg";
import Work from "../resources/images/clock.svg";
// import FooterHome from "../resources/images/footerbg.webp";
// import FooterForAll from "../resources/images/bgffa.png";
import "../styles/Footer.css";

const FooterHome =
  "https://mapsunhomefiles.s3.ir-thr-at1.arvanstorage.com/footerbg.webp";
const FooterForAll =
  "https://mapsunhomefiles.s3.ir-thr-at1.arvanstorage.com/bgffa.webp";

export default class FooterComponent extends Component {
  render = () => (
    <footer className="d-flex flex-column justify-content-around">
      <div className="demo-request">
        <h2>جهت دریافت اطلاعات بیشتر درخواست جلسه دمو ارسال نمائید</h2>
        <a href="/demorequest" className="btn btn-blue btn-large mx-5">
          درخواست جلسه دمو
        </a>
      </div>
      <div className="row border-top-enable">
        <div className="col-md-5 col-xs-0 footer-feature-container">
          <h3 className="no-letter-spacing footer-title mt-4">امکانات</h3>
          <div className="row">
            <ul className="col-6 footer-ul">
              <li>
                <a href="https://mapsun.net">برنامه ریزی و کنترل پروژه</a>
              </li>
              <li>
                <a href="https://mapsun.net/posts/">نظارت بر اجرای پروژه</a>
              </li>
              <li>
                <a href="https://mapsun.net/training/">خرید و تدارکات پروژه</a>
              </li>
              <li>
                <a href="http://behinegar.net/" rel="nofollow">
                  متره و برآورد پروژه
                </a>
              </li>
              <li>
                <a href="https://mapsun.net">گردش کالا و مصالح پروژه</a>
              </li>
              <li>
                <a href="https://mapsun.net/posts/">قراردادها و پیمانکاران</a>
              </li>
              <li>
                <a href="https://mapsun.net/training/">تایم شیت تیم پروژه</a>
              </li>
              <li>
                <a href="http://behinegar.net/" rel="nofollow">
                  مدیریت جلسات پروژه
                </a>
              </li>
            </ul>
            <ul className="row-6 footer-ul">
              <li>
                <a href="https://mapsun.net">مدیریت هزینه های پروژه</a>
              </li>
              <li>
                <a href="https://mapsun.net/posts/">کنترل بودجه پروژه</a>
              </li>
              <li>
                <a href="https://mapsun.net/training/">فرم ساز مپسان</a>
              </li>
              <li>
                <a href="http://behinegar.net/" rel="nofollow">
                  {" "}
                  گردش های کاری فرآیند ها{" "}
                </a>
              </li>
              <li>
                <a href="https://mapsun.net">داشبورد پروژه ها</a>
              </li>
              <li>
                <a href="https://mapsun.net/posts/">مدیریت مدارک و مستندات</a>
              </li>
              <li>
                <a href="https://mapsun.net/training/">مدیریت ذینفعان پروژه</a>
              </li>
              <li>
                <a href="http://behinegar.net/" rel="nofollow">
                  {" "}
                  امنیت نرم افزار مپسان{" "}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-2 col-md-2 col-xs-6 d-none d-md-block">
          <h3 className="no-letter-spacing footer-title mt-4">لینک ها</h3>
          <ul className="footer-ul">
            <li>
              <a href="https://mapsun.net">خانه</a>
            </li>
            <li>
              <a href="https://mapsun.net/pricelist/">لیست قیمت</a>
            </li>
            <li>
              <a href="https://mapsun.net/posts/">نوشته ها</a>
            </li>
            <li>
              <a href="https://mapsun.net/training/">مرکز آموزش</a>
            </li>
            <li>
              <a href="http://behinegar.net/" rel="nofollow">
                درباره بهینه گر
              </a>
            </li>
          </ul>
        </div>
        <div className="col-xs-12 footer-contact-us">
          <h3 className="no-letter-spacing footer-title mt-4">
            با ما در ارتباط باشید
          </h3>
          <div className="footer-ul mt-4">
            <ul className="footer-ul">
              {/* <li>
                <p className="footer-number footer-address">
                  <img src={Location} alt="" />
                  مشهد بزرگراه وکیل آباد - بلوار صیاد شیرازی - نبش صیاد شیرازی
                  21 - پلاک 2 - طبقه اول
                </p>
              </li> */}
              <li>
                <img src={Phone} alt="" className="footer-img" />
                <a
                  href="tel:+982182804770"
                  className="footer-number footer-phone"
                  style={{ direction: "ltr" }}
                >
                  82804770 - 021
                </a>
              </li>
              {/* <li>
                <img src={Mobile} alt="" className="footer-img" />
                <a
                  href="tel:+989151036833"
                  className="footer-number footer-mobile"
                >
                  09151036833
                </a>
              </li> */}
              <li>
                <p className="description-paragraph-footer make-descriptions-smaller footer-number">
                  <img src={Work} alt="" className="footer-img" />
                  ساعات کاری شنبه تا چهارشنبه 8 الی 17
                </p>
              </li>
              <li>
                <h3 className="footer-title mt-4">شبکه های اجتماعی</h3>
              </li>
              <li className="d-flex">
                <li className="ml-2">
                  <a
                    href="https://www.instagram.com/mapsun.proj.mgmt/?igshid=jp6dg4fon9qk"
                    rel="nofollow"
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      id="instagramIcon"
                      className="baseIcon"
                    />
                  </a>
                </li>
                <li className="mx-2">
                  <a
                    href="https://ir.linkedin.com/in/%D9%85%D9%BE%D8%B3%D8%A7%D9%86-%D9%85%D9%BE%D8%B3%D8%A7%D9%86-553905175"
                    rel="nofollow"
                  >
                    <FontAwesomeIcon
                      icon={faLinkedin}
                      id="linkedinIcon"
                      className="baseIcon"
                    />
                  </a>
                </li>
                <li className="mx-2">
                  <FontAwesomeIcon
                    icon={faTwitter}
                    id="twitterIcon"
                    className="baseIcon"
                  />
                </li>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row no-gutters justify-content-center d-none d-md-flex">
        <div className="col-12 text-left border-top-enable">
          <p className="footer-footer mt-3 mx-5">
            تمامی حقوق متعلق به توسعه الگوریتم های بهینه گر می باشد.
          </p>
        </div>
      </div>
    </footer>
  );
}
