import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import "../styles/Carousel.css";
// import i1 from "../resources/images/clients-khorasan.jpg";
// import i2 from "../resources/images/clients-isfahan.jpg";
// import i3 from "../resources/images/clients-kian.png";
// import i4 from "../resources/images/clients-aflakpey.png";
// import i5 from "../resources/images/clients-dalahoo.png";
// import i6 from "../resources/images/clients-futech.jpg";
// import i7 from "../resources/images/clients-yazd.jpg";
// import i8 from "../resources/images/clients-signoffice.jpg";
// import i9 from "../resources/images/clients-khanebana.png";
// import i10 from "../resources/images/clients-sarv.png";
// import i11 from "../resources/images/clients-sesook.jpg";
// import i12 from "../resources/images/clients-gitigostar.png";

const i1 =
  "https://mapsunclients.s3.ir-thr-at1.arvanstorage.com/clients-khorasan.webp";
const i2 =
  "https://mapsunclients.s3.ir-thr-at1.arvanstorage.com/clients-isfahan.webp";
const i3 =
  "https://mapsunclients.s3.ir-thr-at1.arvanstorage.com/clients-kian.webp";
const i4 =
  "https://mapsunclients.s3.ir-thr-at1.arvanstorage.com/clients-aflakpey.webp";
const i5 =
  "https://mapsunclients.s3.ir-thr-at1.arvanstorage.com/clients-dalahoo.webp";
const i6 =
  "https://mapsunclients.s3.ir-thr-at1.arvanstorage.com/client-honar.webp";
const i7 =
  "https://mapsunclients.s3.ir-thr-at1.arvanstorage.com/clients-yazd.webp";
const i8 =
  "https://mapsunclients.s3.ir-thr-at1.arvanstorage.com/clients-signoffice.webp";
const i9 =
  "https://mapsunclients.s3.ir-thr-at1.arvanstorage.com/clients-khanebana.webp";
const i10 =
  "https://mapsunclients.s3.ir-thr-at1.arvanstorage.com/clients-sarv.webp";
const i11 =
  "https://mapsunclients.s3.ir-thr-at1.arvanstorage.com/clients-sesook.webp";
const i12 =
  "https://mapsunclients.s3.ir-thr-at1.arvanstorage.com/clients-hamgoon.webp";
const i13 =
  "https://mapsunclients.s3.ir-thr-at1.arvanstorage.com/client-hirad.webp";
const i14 =
  "https://mapsunclients.s3.ir-thr-at1.arvanstorage.com/clients-tci.webp";
const i15 =
  "https://mapsunclients.s3.ir-thr-at1.arvanstorage.com/clients-futech.webp";
export default class SliderComponent extends Component {
  state = {
    logos: [
      {
        id: 1,
        image: i14,
        title: "شرکت مخابرات ایران",
      },
      {
        id: 2,
        image: i2,
        title: "مخابرات منطقه اصفهان",
      },
      {
        id: 3,
        image: i6,
        title: "هنر سنگ طراحان",
      },
      {
        id: 4,
        image: i4,
        title: "افلاک پی آبتین",
      },
      {
        id: 14,
        image: i5,
        title: "برج آب دالاهو",
      },
      {
        id: 6,
        image: i3,
        title: "شرکت برج کیان ",
      },
      {
        id: 7,
        image: i7,
        title: "مخابرات منطقه یزد",
      },
      {
        id: 8,
        image: i8,
        title: "گروه معماری ساین آفیس",
      },
      {
        id: 9,
        image: i13,
        title: "ابنیه تاسیسات هیراد",
      },
      {
        id: 10,
        image: i10,
        title: "گروه ساختمانی سرو",
      },
      {
        id: 11,
        image: i11,
        title: "شرکت عمرانی سه سوک",
      },
      {
        id: 12,
        image: i12,
        title: "مهندسین مشاور همگون",
      },
      {
        id: 13,
        image: i9,
        title: "خانه بنا",
      },
      {
        id: 5,
        image: i1,
        title: "مخابرات منطقه خراسان رضوی",
      },
      {
        id: 13,
        image: i15,
        title: "فناوری سپید آینده",
      },
    ],
  };
  render = () => (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <a
            className="btn-carousel btn-carousel-prev"
            href="#carouselExampleIndicators2"
            role="button"
            data-slide="prev"
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              id="left-arrow"
              className="baseIcon"
              size="lg"
            />
          </a>
          <a
            className="btn-carousel btn-carousel-next"
            href="#carouselExampleIndicators2"
            role="button"
            data-slide="next"
          >
            <FontAwesomeIcon
              icon={faChevronRight}
              id="right-arrow"
              className="baseIcon"
              size="lg"
            />
          </a>
          <div
            id="carouselExampleIndicators2"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="row">
                  {[
                    this.state.logos[0],
                    this.state.logos[1],
                    this.state.logos[2],
                  ].map((item) => (
                    <div className="col-4 mb-3" key={item.id}>
                      <div className="card">
                        <img
                          src={item.image}
                          className="card-img-top"
                          alt={item.title}
                          style={{
                            maxWidth: "126px",
                            maxHeight: "122px",
                            margin: "auto",
                          }}
                        />
                        <div className="card-body text-center">
                          <h4 className="card-title">{item.title}</h4>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  {[
                    this.state.logos[3],
                    this.state.logos[4],
                    this.state.logos[5],
                  ].map((item) => (
                    <div className="col-4 mb-3" key={item.id}>
                      <div className="card">
                        <img
                          src={item.image}
                          className="card-img-top"
                          alt={item.title}
                          style={{
                            maxWidth: "126px",
                            maxHeight: "122px",
                            margin: "auto",
                          }}
                        />
                        <div className="card-body text-center">
                          <h4 className="card-title">{item.title}</h4>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  {[
                    this.state.logos[6],
                    this.state.logos[7],
                    this.state.logos[8],
                  ].map((item) => (
                    <div className="col-4 mb-3" key={item.id}>
                      <div className="card">
                        <img
                          src={item.image}
                          className="card-img-top"
                          alt={item.title}
                          style={{
                            maxWidth: "126px",
                            maxHeight: "122px",
                            margin: "auto",
                          }}
                        />
                        <div className="card-body text-center">
                          <h4 className="card-title">{item.title}</h4>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  {[
                    this.state.logos[9],
                    this.state.logos[10],
                    this.state.logos[11],
                  ].map((item) => (
                    <div className="col-4 mb-3" key={item.id}>
                      <div className="card">
                        <img
                          src={item.image}
                          className="card-img-top"
                          alt={item.title}
                          style={{
                            maxWidth: "126px",
                            maxHeight: "122px",
                            margin: "auto",
                          }}
                        />
                        <div className="card-body text-center">
                          <h4 className="card-title">{item.title}</h4>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  {[
                    this.state.logos[12],
                    this.state.logos[13],
                    this.state.logos[14],
                  ].map((item) => (
                    <div className="col-4 mb-3" key={item.id}>
                      <div className="card">
                        <img
                          src={item.image}
                          className="card-img-top"
                          alt={item.title}
                          style={{
                            maxWidth: "126px",
                            maxHeight: "122px",
                            margin: "auto",
                          }}
                        />
                        <div className="card-body text-center">
                          <h4 className="card-title">{item.title}</h4>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
