import React, { Component } from "react";
import "../styles/HeaderInfographic.css";
import SlideCruze from "../resources/images/slide-cruz.svg";
import Phone from "../resources/images/phone-thin.svg";

class HeaderInfographic extends Component {
  render = () => (
    <div className="row">
      <ul className="navbar-nav" id="navbar-phone-onMobile">
        <li
          className="nav-item"
          style={{
            display: "inline-block",
            border: "2px solid rgba(255,255,255,0.6)",
            borderRadius: "10px",
          }}
        >
          <a
            href="tel:+982182804770"
            className="nav-link footer-number footer-phone"
            style={{
              direction: "ltr",
            }}
          >
            <img
              src={Phone}
              alt=""
              className="nav-link footer-img"
              style={{ color: "whitesmoke", width: "1rem", height: "1rem" }}
            />
            021 - 82804770
          </a>
        </li>
      </ul>
      <div className="introduce-col">
        <h2 className="header-title">مپسان </h2>
        <h1 className="header-info">نرم افزار مدیریت پروژه آنلاین</h1>
        <p className="header-text"></p>
        <div className="header-buttons">
          <a href="/demorequest" className="btn btn-blue btn-large">
            درخواست جلسه دمو
          </a>
          <a
            href="https://mapsunapp.ir/auth/login"
            target="_blank"
            rel="noopener noreferrer nofollow"
            className="btn btn-outlined btn-large"
          >
            ورود
          </a>
        </div>
      </div>
    </div>
  );
}

export default HeaderInfographic;
